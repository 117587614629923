import ImgComponent from "../imgComponent"

const SupportContent = () => {
  return (
    <section
      className="elementor-element elementor-element-924d4d1 elementor-section-stretched elementor-section-full_width elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
      data-id="924d4d1"
      data-element_type="section"
      data-settings='{"stretch_section":"section-stretched"}'
    >
      <div className="elementor-container elementor-column-gap-default">
        <div className="elementor-row">
          <div
            className="elementor-element elementor-element-2e1f0cf elementor-column elementor-col-100 elementor-top-column"
            data-id="2e1f0cf"
            data-element_type="column"
            data-settings='{"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
          >
            <div className="elementor-column-wrap elementor-element-populated">
              <div className="elementor-widget-wrap">
                <div
                  className="elementor-element elementor-element-14aaa15 elementor-widget elementor-widget-avante-gallery-grid"
                  data-id="14aaa15"
                  data-element_type="widget"
                  data-settings='{"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                  data-widget_type="avante-gallery-grid.default"
                >
                  <div className="elementor-widget-container">
                    <div className="avante-gallery-grid-content-wrapper layout-avante-four-cols ">
                      <div
                        className="gallery-grid-item avante-four-cols emir"
                        data-delay={10}
                        data-minwidth={769}
                        data-scale-x={0}
                        data-scale-y={0}
                      >
                        <a
                          className="tg_gallery_lightbox"
                          href="/static/12c09ac7db320308bc6d7ac077034f56/c5f21/support1.jpg"
                          data-thumb="/static/12c09ac7db320308bc6d7ac077034f56/c5f21/support1.jpg"
                          data-rel="tg_gallery14aaa15"
                        >
                          <ImgComponent pmlImg="support1.jpg" />
                        </a>
                      </div>
                      <div
                        className="gallery-grid-item avante-four-cols emir"
                        data-delay={10}
                        data-minwidth={769}
                        data-scale-x={0}
                        data-scale-y={0}
                      >
                        <a
                          className="tg_gallery_lightbox"
                          href="/static/df32674026c23661e252c6c1c7cc548c/c5f21/support2.jpg"
                          data-thumb="/static/df32674026c23661e252c6c1c7cc548c/c5f21/support2.jpg"
                          data-rel="tg_gallery14aaa15"
                        >
                          <ImgComponent pmlImg="support2.jpg" />
                        </a>
                      </div>
                      <div
                        className="gallery-grid-item avante-four-cols emir"
                        data-delay={10}
                        data-minwidth={769}
                        data-scale-x={0}
                        data-scale-y={0}
                      >
                        <a
                          className="tg_gallery_lightbox"
                          href="/static/72a97bfe25f5efd6d89ed4d386f00fad/c5f21/support3.jpg"
                          data-thumb="/static/72a97bfe25f5efd6d89ed4d386f00fad/c5f21/support3.jpg"
                          data-rel="tg_gallery14aaa15"
                        >
                          <ImgComponent pmlImg="support3.jpg" />
                        </a>
                      </div>
                      <div
                        className="gallery-grid-item avante-four-cols last emir"
                        data-delay={10}
                        data-minwidth={769}
                        data-scale-x={0}
                        data-scale-y={0}
                      >
                        <a
                          className="tg_gallery_lightbox"
                          href="/static/cc5b7abd982bf8420516556edbc0f392/c5f21/support4.jpg"
                          data-thumb="/static/cc5b7abd982bf8420516556edbc0f392/c5f21/support4.jpg"
                          data-rel="tg_gallery14aaa15"
                        >
                          <ImgComponent pmlImg="support4.jpg" />
                        </a>
                      </div>
                      <div
                        className="gallery-grid-item avante-four-cols emir"
                        data-delay={10}
                        data-minwidth={769}
                        data-scale-x={0}
                        data-scale-y={0}
                      >
                        <a
                          className="tg_gallery_lightbox"
                          href="/static/7cb31f9feb414c2efa1f41ab515d6556/c5f21/support5.jpg"
                          data-thumb="/static/7cb31f9feb414c2efa1f41ab515d6556/c5f21/support5.jpg"
                          data-rel="tg_gallery14aaa15"
                        >
                          <ImgComponent pmlImg="support5.jpg" />
                        </a>
                      </div>

                      <br className="clear" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SupportContent
