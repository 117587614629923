const AnalyticalTitle = () => {
  return (
    <section
      className="elementor-element elementor-element-a41322f elementor-section-stretched elementor-section-height-min-height elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-items-middle elementor-section elementor-top-section"
      data-id="a41322f"
      data-element_type="section"
      data-settings='{"stretch_section":"section-stretched","background_background":"classic"}'
    >
      <div className="elementor-container elementor-column-gap-default">
        <div className="elementor-row">
          <div
            className="elementor-element elementor-element-33edc81 elementor-column elementor-col-100 elementor-top-column"
            data-id="33edc81"
            data-element_type="column"
            data-settings='{"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
          >
            <div className="elementor-column-wrap elementor-element-populated">
              <div className="elementor-widget-wrap">
                <div
                  className="elementor-element elementor-element-bb5f7f6 elementor-widget__width-inherit elementor-widget elementor-widget-heading"
                  data-id="bb5f7f6"
                  data-element_type="widget"
                  data-settings='{"":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_rotatex":{"unit":"px","size":-90,"sizes":[]},"avante_ext_smoove_translatex":{"unit":"px","size":-40,"sizes":[]},"avante_ext_smoove_translatey":{"unit":"px","size":40,"sizes":[]},"avante_ext_smoove_translatez":{"unit":"px","size":-140,"sizes":[]},"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_scaley":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_rotatey":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewx":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewy":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_perspective":{"unit":"px","size":1000,"sizes":[]},"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                  data-widget_type="heading.default"
                >
                  <div className="elementor-widget-container">
                    <h1
                      className="elementor-heading-title elementor-size-default"
                      style={{ paddingTop: "8rem" }}
                      id="our-facilities"
                    >
                      Our Facilities
                    </h1>
                  </div>

                  <div className="elementor-widget-container">
                    <h3
                      className="elementor-heading-title2 elementor-size-default titleHijau2"
                      id="title-gallery"
                      style={{ paddingTop: "4rem" }}
                    >
                      Analytical Facilities
                    </h3>
                  </div>
                  <div class="elementor-widget-container">
                    <div class="elementor-heading-title2 elementor-size-default padding-bawah-gallery">
                      <p
                        style={{ paddingTop: "0px" }}
                        id="penjelas-title-gallery"
                      >
                        Gallery of Our Instruments and Lab Facilities
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AnalyticalTitle
