import React from "react"
import ContactBus from "../content/ContactBus"
import ContentAF from "../content/Gallery/AnalyticalContent"
import TitleAF from "../content/Gallery/AnalyticalTitle"
import ContentCF from "../content/Gallery/ClinicalContent"
import TitleCF from "../content/Gallery/ClinicalTitle"
import ContentSF from "../content/Gallery/SupportContent"
import TitleSF from "../content/Gallery/SupportTitle"
import Mobile from "../content/Mobile"
import SEO from "../SEO"
import "./cssberhasil"
import Footer from "./Footer"
import Header from "./HeaderSub"
import Navbar from "./NavbarSub"

import Helmet from "react-helmet"
import JSberhasil from "./jsberhasil"

const LayoutGallery = () => {
  return (
    // <>
    <React.Fragment>
      <Helmet
        bodyAttributes={{
          class:
            "home page-template-default page page-id-4074 theme-avante woocommerce-no-js menu-transparent lightbox-black leftalign footer-reveal elementor-default elementor-page elementor-page-4074",
        }}
      />
      <SEO
        title="Pharmametrics Lab | Facilities at PML Indonesia CRO Company"
        description="We as CRO company in Indonesia have clinical trial, analytical, and support facilities that will fulfill your company needs"
      />
      {/* <div id="loftloader-wrapper" className="pl-imgloading" data-show-close-time="15000">
                <div className="loader-inner">
                    <div id="loader">
                        <div className="imgloading-container">
                            <span style= {{backgroundImage: `url(${LoadingLogo})`}} ></span>
                        </div>
                        <img alt="loader image" src={LoadingLogo} />
                    </div>
                </div>
                <div className="loader-section section-fade">
                </div>
                <div className="loader-close-button">
                    <span className="screen-reader-text">Close</span>
                </div>
            </div> */}

      <div id="perspective">
        <Mobile />
        <div id="wrapper" class="hasbg transparent">
          <div id="elementor-header" class="main-menu-wrapper">
            <div
              data-elementor-type="wp-post"
              data-elementor-id="4287"
              class="elementor elementor-4287"
              data-elementor-settings="[]"
            >
              <div class="elementor-inner">
                <div class="elementor-section-wrap">
                  <Header />
                  <Navbar />
                </div>
              </div>
            </div>
          </div>
          <div id="elementor-sticky-header" className="main-menu-wrapper">
            <div
              data-elementor-type="wp-post"
              data-elementor-id="4287"
              className="elementor elementor-4287"
              data-elementor-settings="[]"
            >
              <div className="elementor-inner">
                <div className="elementor-section-wrap">
                  <Navbar />
                  {/* <Header /> */}
                </div>
              </div>
            </div>
          </div>

          <div id="page-content-wrapper" class="">
            <div class="inner">
              <div class="inner-wrapper">
                <div class="sidebar-content fullwidth">
                  <div
                    data-elementor-type="wp-page"
                    data-elementor-id="3734"
                    class="elementor elementor-3734"
                    data-elementor-settings="[]"
                  >
                    <div class="elementor-inner">
                      <div class="elementor-section-wrap">
                        <TitleAF />
                        <ContentAF />
                        <TitleCF />
                        <ContentCF />
                        <TitleSF />
                        <ContentSF />

                        {/* <ContactBus /> */}
                      </div>
                    </div>
                  </div>

                  <div
                    data-elementor-type="wp-page"
                    data-elementor-id="5459"
                    className="elementor elementor-5459"
                    data-elementor-settings="[]"
                  >
                    <ContactBus />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <JSberhasil />
    </React.Fragment>
  )
}

export default LayoutGallery
