import ImgComponent from "../imgComponent"

const ClinicalContent = () => {
  return (
    <section
      className="elementor-element elementor-element-924d4d1 elementor-section-stretched elementor-section-full_width elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
      data-id="924d4d1"
      data-element_type="section"
      data-settings='{"stretch_section":"section-stretched"}'
    >
      <div className="elementor-container elementor-column-gap-default">
        <div className="elementor-row">
          <div
            className="elementor-element elementor-element-2e1f0cf elementor-column elementor-col-100 elementor-top-column"
            data-id="2e1f0cf"
            data-element_type="column"
            data-settings='{"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
          >
            <div className="elementor-column-wrap elementor-element-populated">
              <div className="elementor-widget-wrap">
                <div
                  className="elementor-element elementor-element-14aaa15 elementor-widget elementor-widget-avante-gallery-grid"
                  data-id="14aaa15"
                  data-element_type="widget"
                  data-settings='{"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                  data-widget_type="avante-gallery-grid.default"
                >
                  <div className="elementor-widget-container">
                    <div className="avante-gallery-grid-content-wrapper layout-avante-four-cols ">
                      <div
                        className="gallery-grid-item avante-four-cols emir"
                        data-delay={10}
                        data-minwidth={769}
                        data-scale-x={0}
                        data-scale-y={0}
                      >
                        <a
                          className="tg_gallery_lightbox"
                          href="/static/dc27b7143d51b2d1c5cc831a72a4553a/c5f21/clinical1.jpg"
                          data-thumb="/static/dc27b7143d51b2d1c5cc831a72a4553a/c5f21/clinical1.jpg"
                          data-rel="tg_gallery14aaa15"
                        >
                          <ImgComponent pmlImg="clinical1.jpg" />
                        </a>
                      </div>
                      <div
                        className="gallery-grid-item avante-four-cols emir"
                        data-delay={10}
                        data-minwidth={769}
                        data-scale-x={0}
                        data-scale-y={0}
                      >
                        <a
                          className="tg_gallery_lightbox"
                          href="/static/c6ef164eefae8dac38245cd149c1b75d/c5f21/clinical2.jpg"
                          data-thumb="/static/c6ef164eefae8dac38245cd149c1b75d/c5f21/clinical2.jpg"
                          data-rel="tg_gallery14aaa15"
                        >
                          <ImgComponent pmlImg="clinical2.jpg" />
                        </a>
                      </div>
                      <div
                        className="gallery-grid-item avante-four-cols emir"
                        data-delay={10}
                        data-minwidth={769}
                        data-scale-x={0}
                        data-scale-y={0}
                      >
                        <a
                          className="tg_gallery_lightbox"
                          href="/static/534719b24a1658462eef44849b484b3a/c5f21/clinical3.jpg"
                          data-thumb="/static/534719b24a1658462eef44849b484b3a/c5f21/clinical3.jpg"
                          data-rel="tg_gallery14aaa15"
                        >
                          <ImgComponent pmlImg="clinical3.jpg" />
                        </a>
                      </div>
                      <div
                        className="gallery-grid-item avante-four-cols last emir"
                        data-delay={10}
                        data-minwidth={769}
                        data-scale-x={0}
                        data-scale-y={0}
                      >
                        <a
                          className="tg_gallery_lightbox"
                          href="/static/a21b64d94973bbb6808e22bef1b085c1/c5f21/clinical4.jpg"
                          data-thumb="/static/a21b64d94973bbb6808e22bef1b085c1/c5f21/clinical4.jpg"
                          data-rel="tg_gallery14aaa15"
                        >
                          <ImgComponent pmlImg="clinical4.jpg" />
                        </a>
                      </div>
                      <div
                        className="gallery-grid-item avante-four-cols emir"
                        data-delay={10}
                        data-minwidth={769}
                        data-scale-x={0}
                        data-scale-y={0}
                      >
                        <a
                          className="tg_gallery_lightbox"
                          href="/static/a2148f31d0dc6b4602cad1bc2c29c2eb/c5f21/clinical5.jpg"
                          data-thumb="/static/a2148f31d0dc6b4602cad1bc2c29c2eb/c5f21/clinical5.jpg"
                          data-rel="tg_gallery14aaa15"
                        >
                          <ImgComponent pmlImg="clinical5.jpg" />
                        </a>
                      </div>
                      <div
                        className="gallery-grid-item avante-four-cols emir"
                        data-delay={10}
                        data-minwidth={769}
                        data-scale-x={0}
                        data-scale-y={0}
                      >
                        <a
                          className="tg_gallery_lightbox"
                          href="/static/f106d520c58bf2f8f8df14a0c73a9317/c5f21/clinical6.jpg"
                          data-thumb="/static/f106d520c58bf2f8f8df14a0c73a9317/c5f21/clinical6.jpg"
                          data-rel="tg_gallery14aaa15"
                        >
                          <ImgComponent pmlImg="clinical6.jpg" />
                        </a>
                      </div>

                      <br className="clear" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ClinicalContent
